
import { defineComponent } from 'vue'
import * as echarts from 'echarts'
import { getInsuranceTrend } from '@/api/insurance'
export default defineComponent({
    name: 'HomeInsurance',
    // components: { 'insurance-plan': InsurancePlan },
    data () {
        return {
            // data: {} as InsuranceOpenDto[]
        }
    },
    async mounted () {
        echarts.dispose(document.getElementById('trend') as HTMLElement)
        const result = await getInsuranceTrend()
        const charts = echarts.init(
            document.getElementById('trend') as HTMLElement
        )
        charts.setOption({
            color: ['#2EA1FF'],
            xAxis: {
                type: 'category',
                data: result.month,
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false,
                    alignWithLabel: true
                }
            },
            yAxis: {
                type: 'value',
                boundaryGap: ['0%', '10%']
            },
            series: [
                {
                    data: result.premium,
                    type: 'bar',
                    barWidth: 20
                }
            ],
            tooltip: {
                show: true
            },
            grid: {
                x: 50,
                y: 20,
                x2: 50,
                y2: 60
            }
        })
    },
    methods: {
        more () {
            this.$router.push({
                path: '/insurance/plan'
            })
        }
    }
})
