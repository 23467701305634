
import { defineComponent } from 'vue'
import * as echarts from 'echarts'
import { workersAnlysis, WorkersDataDto } from '@/api/home'

interface DataCfg {
    name: string
    itemStyle: {
        color: string
    },
    label?: {
        show: boolean
    }
}

interface LinkCfg {
    source: string
    target: string
    value: number
}

const COLOR = ['#2EA1FF', '#1FD1A2', '#FFC757', '#F95185', '#6464F9', '#7585A2', '#FF99C3']
export default defineComponent({
    name: 'WorkersAnlysis',
    data () {
        return {
            data: {} as WorkersDataDto,
            isEmpty: false,
            malePercent: '',
            femalePercent: '',
            maleIconNum: 0,
            femaleIconNum: 0
        }
    },

    async mounted () {
        echarts.dispose(document.getElementById('anlysis') as HTMLElement)
        const result = await workersAnlysis()
        this.data = result
        this.isEmpty = result.active_num === 0
        // 处理数据
        if (result.active_num === 0) return
        this.malePercent = (100 * result.male_num / result.active_num).toFixed(2)
        this.femalePercent = (100 - Number(this.malePercent)).toFixed(2)
        this.maleIconNum = Math.round(Number(this.malePercent) / 5)
        this.femaleIconNum = Math.round(Number(this.femalePercent) / 5)
        const charts = echarts.init(
            document.getElementById('anlysis') as HTMLElement
        )
        const names:DataCfg[] = [{
            name: 'a',
            itemStyle: {
                color: '#FFF3D1'
            },
            label: {
                show: false
            }
        }]
        const values:LinkCfg[] = []
        result.age_section.map((item, index) => {
            names.push({
                name: item.name,
                itemStyle: {
                    color: COLOR[index]
                }
            })
            values.push({
                source: 'a',
                target: item.name,
                value: item.value
            })
        })
        charts.setOption({
            series: {
                type: 'sankey',
                layout: 'none',
                top: 15,
                left: 0,
                right: 60,
                layoutIterations: 0,
                label: {
                    color: '#8691A3'
                },
                lineStyle: {
                    color: 'target'
                },
                data: names,
                links: values
            }
        })
    },
    methods: {

    }
})
