
import { defineComponent } from 'vue'
import { getBasicData, BasicDataDto } from '@/api/home'
import tips from './tips.vue'

export default defineComponent({
    name: 'HomeDetail',
    components: {
        'component-tips': tips
    },
    data () {
        return {
            data: {} as BasicDataDto
        }
    },
    async created () {
        const result = await getBasicData()
        this.data = result
    },
    methods: {}
})
