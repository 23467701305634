
import { defineComponent } from 'vue'
import { TimeInfo } from '@/api/home'
const imgs = ['morning', 'midday', 'evening']
const chs = ['早上好', '下午好', '晚上好']
export default defineComponent({
    name: 'HomeDetail',
    data () {
        return {
            timeInfo: {} as TimeInfo
        }
    },
    props: ['days', 'greeting'],
    async created () {
        this.createHello()
    },
    methods: {
        createHello () {
            const hours = new Date().getHours()
            if (hours > 5 && hours < 12) {
                this.timeInfo = {
                    img: require(`@/assets/img/home/${imgs[0]}.png`),
                    label: chs[0]
                }
            } else if (hours >= 12 && hours <= 18) {
                this.timeInfo = {
                    img: require(`@/assets/img/home/${imgs[1]}.png`),
                    label: chs[1]
                }
            } else {
                this.timeInfo = {
                    img: require(`@/assets/img/home/${imgs[2]}.png`),
                    label: chs[2]
                }
            }
        }
    }
})
