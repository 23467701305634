
import { defineComponent } from 'vue'
import InsurancePlan from '../../components/insurance-plan.vue'
import { getInsuranceOpenData, InsuranceOpenDto } from '@/api/insurance'
export default defineComponent({
    name: 'HomeOpen',
    components: { 'insurance-plan': InsurancePlan },
    data () {
        return {
            data: {} as InsuranceOpenDto[]
        }
    },
    async created () {
        const result = await getInsuranceOpenData()
        this.data = result
    },
    methods: {
        more () {
            this.$router.push({
                path: '/insurance/plan'
            })
        }
    }
})
